<template>
  <div>
    <a-row class="left">
        <a-col class="title">关于明确增值税小规模纳税人减免增值税等政策的公告</a-col>
        <a-col class="date"><a-icon type="clock-circle" /> 2023-01-01</a-col>
        <a-divider class="main"></a-divider>
        <div class="content">
            <p><b>财政部 税务总局公告2023年第1号</b></p>
            <br/>
            <p class="row">一、自2023年1月1日至2023年12月31日,对月销售额10万元以下（含本数）的增值税小规模纳税人，免征增值税。</p>
            <br/>
            <p class="row">二、自2023年1月1日至2023年12月31日，增值税小规模纳税人适用3%征收率的应税销售收入，减按1%征收率征收增值税；适用3%预征率的预缴增值税项目，减按1%预征率预缴增值税。</p>
            <br/>
            <p class="row">三、自2023年1月1日至2023年12月31日，增值税加计抵减政策按照以下规定执行：</p>
            <br/>
            <p class="row">（一）允许生产性服务业纳税人按照当期可抵扣进项税额加计5%抵减应纳税额。生产性服务业纳税人，是指提供邮政服务、电信服务、现代服务、生活服务取得的销售额占全部销售额的比重超过50%的纳税人。</p>
            <br/>
            <p class="row">（二）允许生活性服务业纳税人按照当期可抵扣进项税额加计10%抵减应纳税额。生活性服务业纳税人，是指提供生活服务取得的销售额占全部销售额的比重超过50%的纳税人。</p>
            <br/>
            <p class="row">（三）纳税人适用加计抵减政策的其他有关事项，按照《财政部 税务总局 海关总署关于深化增值税改革有关政策的公告》（财政部税务总局 海关总署公告2019年第39号）、《财政部 税务总局关于明确生活性服务业增值税加计抵减政策的公告》（财政部税务总局公告2019年第87号）等有关规定执行。</p>
            <br/>
            <p class="row">四、按照本公告规定，应予减免的增值税，在本公告下发前已征收的，可抵减纳税人以后纳税期应缴纳税款或予以退还。特此公告。</p>
            <br/>
            <p class="text-right">财政部   税务总局</p>
            <br/>
            <p class="text-right">2023年1月9日</p>
        </div>

        <a-row :gutter="20">
            <a-col :span="12">
                <div class="next"  @click="$router.push('/notice-details/s5')">
                    <div class="align">
                        <p class="to">上一篇</p>
                        <p class="to-title">91再生网依法进行主体登记，履行纳税义务</p>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="next"  @click="$router.push('/notice-details/s1')">
                    <div class="align">
                        <p class="to">下一篇</p>
                        <p class="to-title">91再生税票平台上线啦！</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-row>

    <a-row class="right">
        <a-col class="date">其他公告</a-col>
        <a-divider class="main"></a-divider>
        
        <a-row class="r-item" v-for="item in list" :key="item.title">
            <a-col class="to-title" @click="$router.push(item.router)">{{item.title}}</a-col>
            <a-col class="r-text">{{item.text}}</a-col>
            <a-col class="r-date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
            <a-divider></a-divider>
        </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {title:'增值税1%征收率即将结束',text:'小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收...',router: '/notice-details/s2'},
                {title:'91再生交易平台执行“实名认证”入驻',text:'为确保交易自然人的知悉权，避免身份被他人借用的情况，平台卖家入驻时需...',router: '/notice-details/s3'},
                {title:'关于个体工商户发票存证限额的通知',text:'根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》...',router: '/notice-details/s4'},
                {title:'91再生网依法进行主体登记，履行纳税义务',text:'依据《中华人民共和国电子商务法》, 91再生交易平台依法进行主体登记，履行纳税义务...',router: '/notice-details/s5'},
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.left {
    @media screen and (max-width: 1440px){
        width: 60%;
    }
    display: inline-block;
    vertical-align: top;
    width: 960px;
    margin-right: 42px;
}
.right {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1440px){
        width: calc(40% - 42px);
    }
    width: 352px;
    line-height: 24px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
.date {
    color: #AAAAAA;
    font-size: 16px;
}
.content {
    p{
        line-height: 26px;
        &.row{
            text-indent: 2em;
        }
        &.text-right{
            text-align: right;
        }
    }
    li {
        margin-left: 3.5em;
        line-height: 26px;
        list-style: initial;
    }
}
.to-title {
    cursor: pointer;
    font-weight: 700;
    color: #666666;
    font-size: 18px;
    transition: all .1s;
    &:hover {
        color: rgba(234, 164, 0, 1);
    }
}
.next {
    margin: 62px 0;
    cursor: pointer;
    border-left: 3px solid rgba(215, 215, 215, 1);
    background-color: rgba(242, 242, 242, 1);
    height: 100px;
    position: relative;
    transition: all .1s;
    .align {
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        .to {
            color: rgb(170, 170, 170);
            line-height: 28px;
        }
    }
    &:hover {
        background-color: rgba(68, 165, 40, 1);
        .to,.to-title {
            color: #fff !important;
        }
    }
}
.r-item {
    .r-date {
        color: #AAAAAA;
        font-size: 14px;
    }
    .r-text {
        font-size: 14px;
        color: #AAAAAA;
        margin-top: 6px;
        line-height: 24px;
    }
}
/deep/ .ant-divider-horizontal{
    margin: 12px 0;
}
.main {
    background: #d7d7d7;
    margin: 12px 0 16px 0;
}
</style>